import * as React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { css } from "@emotion/react"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div
      css={css`
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        padding: 12rem clamp(1.2em, 4vw, 6rem);
      `}
    >
      <h1>Sorry.</h1>
      <p>Nothing exists on this page.</p>
    </div>
  </Layout>
)

export default NotFoundPage
